import React from "react";
import "./Testimon.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
// import pic1 from "../../assets/Courses/Testimonials/Pic1.png";
import pic2 from "../../assets/Courses/Testimonials/left.png"
import pic3 from "../../assets/Courses/Testimonials/right.png"

const TestimonialCard = () => {
  return (
    <>
      <div className="cont relative bg-[#D8E5ED]">
        <h1 className="absolute top-8 text-5xl font-roboto font-semibold text-[#406376]">Testimonials</h1>
        <div className="cards">
          <div className="card relative">
            <div className="rating text-[#002F48] flex justify-center gap-1">
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
            </div>
            <p className="mt-5 text-xl font-roboto text-[#002F48] font-normal leading-8 w-[761px] mx-auto">
              "I joined SG Education in my 11th grade and stayed with them until
              I finished 12th Science. The way Latesh Sir teaches Physics and
              Maths is exceptional. He breaks down difficult concepts into
              manageable parts, which made a huge difference for me. Pooja
              Ma’am’s Chemistry classes were also very engaging, and Varsha
              Ma'am’s Biology teaching was the best! I’m now studying
              Biotechnology, and I owe a lot of my success to SG Education. It’s
              the best classes for Science students in Kalyan."
            </p>

            {/* <div className="absolute -bottom-28 right-[40%] flex flex-col justify-center items-center">
              
              <h3 className="font-bold font-roboto text-2xl text-[#002F48]">Sharvari Gangurde</h3>
              <p className="font-normal font-roboto text-2xl text-[#002F48]">(12th Science)</p>
            </div> */}
            {/* double inverted comma */}
            <div>
              <img src={pic2} alt=""  
              className="absolute top-20  left-36" width={79} height={189}
              />
              <img src={pic3} alt="" 
              className="absolute  bottom-16 right-28" width={79} height={189}
              />
            </div>
          </div>

          <div className="card relative">
            <div className="rating text-[#002F48] flex justify-center gap-1">
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
            </div>
            <p className="mt-5 text-xl font-roboto text-[#002F48] font-normal leading-8 w-[761px] mx-auto">
              "I joined SG Education in my 11th grade and stayed with them until
              I finished 12th Science. The way Latesh Sir teaches Physics and
              Maths is exceptional. He breaks down difficult concepts into
              manageable parts, which made a huge difference for me. Pooja
              Ma’am’s Chemistry classes were also very engaging, and Varsha
              Ma'am’s Biology teaching was the best! I’m now studying
              Biotechnology, and I owe a lot of my success to SG Education. It’s
              the best classes for Science students in Kalyan."
            </p>

            {/* <div className="absolute -bottom-28 right-[40%] flex flex-col justify-center items-center">
              <img src={pic1} className="" alt=" " width={117} />
              <h3 className="font-bold font-roboto text-2xl text-[#002F48]">Sharvari Gangurde</h3>
              <p className="font-normal font-roboto text-2xl text-[#002F48]">(12th Science)</p>
            </div> */}
            {/* double inverted comma */}
            <div>
              <img src={pic2} alt=""  
              className="absolute top-20  left-36" width={79} height={189}
              />
              <img src={pic3} alt="" 
              className="absolute  bottom-16 right-28" width={79} height={189}
              />
            </div>
          </div>

          <div className="card relative">
            <div className="rating text-[#002F48] flex justify-center gap-1">
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
            </div>
            <p className="mt-5 text-xl font-roboto text-[#002F48] font-normal leading-8 w-[761px] mx-auto">
              "I joined SG Education in my 11th grade and stayed with them until
              I finished 12th Science. The way Latesh Sir teaches Physics and
              Maths is exceptional. He breaks down difficult concepts into
              manageable parts, which made a huge difference for me. Pooja
              Ma’am’s Chemistry classes were also very engaging, and Varsha
              Ma'am’s Biology teaching was the best! I’m now studying
              Biotechnology, and I owe a lot of my success to SG Education. It’s
              the best classes for Science students in Kalyan."
            </p>

            {/* <div className="absolute -bottom-28 right-[40%] flex flex-col justify-center items-center">
              <img src={pic1} className="" alt=" " width={117} />
              <h3 className="font-bold font-roboto text-2xl text-[#002F48]">Sharvari Gangurde</h3>
              <p className="font-normal font-roboto text-2xl text-[#002F48]">(12th Science)</p>
            </div> */}
            {/* double inverted comma */}
            <div>
              <img src={pic2} alt=""  
              className="absolute top-20  left-36" width={79} height={189}
              />
              <img src={pic3} alt="" 
              className="absolute  bottom-16 right-28" width={79} height={189}
              />
            </div>
          </div>

          <div className="card relative">
            <div className="rating text-[#002F48] flex justify-center gap-1">
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
            </div>
            <p className="mt-5 text-xl font-roboto text-[#002F48] font-normal leading-8 w-[761px] mx-auto">
              "I joined SG Education in my 11th grade and stayed with them until
              I finished 12th Science. The way Latesh Sir teaches Physics and
              Maths is exceptional. He breaks down difficult concepts into
              manageable parts, which made a huge difference for me. Pooja
              Ma’am’s Chemistry classes were also very engaging, and Varsha
              Ma'am’s Biology teaching was the best! I’m now studying
              Biotechnology, and I owe a lot of my success to SG Education. It’s
              the best classes for Science students in Kalyan."
            </p>

            {/* <div className="absolute -bottom-28 right-[40%] flex flex-col justify-center items-center">
              <img src={pic1} className="" alt=" " width={117} />
              <h3 className="font-bold font-roboto text-2xl text-[#002F48]">Sharvari Gangurde</h3>
              <p className="font-normal font-roboto text-2xl text-[#002F48]">(12th Science)</p>
            </div> */}
            {/* double inverted comma */}
            <div>
              <img src={pic2} alt=""  
              className="absolute top-20  left-36" width={79} height={189}
              />
              <img src={pic3} alt="" 
              className="absolute  bottom-16 right-28" width={79} height={189}
              />
            </div>
          </div>

          <div className="card relative">
            <div className="rating text-[#002F48] flex justify-center gap-1">
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
            </div>
            <p className="mt-5 text-xl font-roboto text-[#002F48] font-normal leading-8 w-[761px] mx-auto">
              "I joined SG Education in my 11th grade and stayed with them until
              I finished 12th Science. The way Latesh Sir teaches Physics and
              Maths is exceptional. He breaks down difficult concepts into
              manageable parts, which made a huge difference for me. Pooja
              Ma’am’s Chemistry classes were also very engaging, and Varsha
              Ma'am’s Biology teaching was the best! I’m now studying
              Biotechnology, and I owe a lot of my success to SG Education. It’s
              the best classes for Science students in Kalyan."
            </p>

            {/* <div className="absolute -bottom-28 right-[40%] flex flex-col justify-center items-center">
              <img src={pic1} className="" alt=" " width={117} />
              <h3 className="font-bold font-roboto text-2xl text-[#002F48]">Sharvari Gangurde</h3>
              <p className="font-normal font-roboto text-2xl text-[#002F48]">(12th Science)</p>
            </div> */}
            {/* double inverted comma */}
            <div>
              <img src={pic2} alt=""  
              className="absolute top-20  left-36" width={79} height={189}
              />
              <img src={pic3} alt="" 
              className="absolute  bottom-16 right-28" width={79} height={189}
              />
            </div>
          </div>

          <div className="card relative">
            <div className="rating text-[#002F48] flex justify-center gap-1">
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
            </div>
            <p className="mt-5 text-xl font-roboto text-[#002F48] font-normal leading-8 w-[761px] mx-auto">
              "I joined SG Education in my 11th grade and stayed with them until
              I finished 12th Science. The way Latesh Sir teaches Physics and
              Maths is exceptional. He breaks down difficult concepts into
              manageable parts, which made a huge difference for me. Pooja
              Ma’am’s Chemistry classes were also very engaging, and Varsha
              Ma'am’s Biology teaching was the best! I’m now studying
              Biotechnology, and I owe a lot of my success to SG Education. It’s
              the best classes for Science students in Kalyan."
            </p>

            {/* <div className="absolute -bottom-28 right-[40%] flex flex-col justify-center items-center">
              <img src={pic1} className="" alt=" " width={117} />
              <h3 className="font-bold font-roboto text-2xl text-[#002F48]">Sharvari Gangurde</h3>
              <p className="font-normal font-roboto text-2xl text-[#002F48]">(12th Science)</p>
            </div> */}
            {/* double inverted comma */}
            <div>
              <img src={pic2} alt=""  
              className="absolute top-20  left-36" width={79} height={189}
              />
              <img src={pic3} alt="" 
              className="absolute  bottom-16 right-28" width={79} height={189}
              />
            </div>
          </div>

          <div className="card relative">
            <div className="rating text-[#002F48] flex justify-center gap-1">
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
            </div>
            <p className="mt-5 text-xl font-roboto text-[#002F48] font-normal leading-8 w-[761px] mx-auto">
              "I joined SG Education in my 11th grade and stayed with them until
              I finished 12th Science. The way Latesh Sir teaches Physics and
              Maths is exceptional. He breaks down difficult concepts into
              manageable parts, which made a huge difference for me. Pooja
              Ma’am’s Chemistry classes were also very engaging, and Varsha
              Ma'am’s Biology teaching was the best! I’m now studying
              Biotechnology, and I owe a lot of my success to SG Education. It’s
              the best classes for Science students in Kalyan."
            </p>

            {/* <div className="absolute -bottom-28 right-[40%] flex flex-col justify-center items-center">
              <img src={pic1} className="" alt=" " width={117} />
              <h3 className="font-bold font-roboto text-2xl text-[#002F48]">Sharvari Gangurde</h3>
              <p className="font-normal font-roboto text-2xl text-[#002F48]">(12th Science)</p>
            </div> */}
            {/* double inverted comma */}
            <div>
              <img src={pic2} alt=""  
              className="absolute top-20  left-36" width={79} height={189}
              />
              <img src={pic3} alt="" 
              className="absolute  bottom-16 right-28" width={79} height={189}
              />
            </div>
          </div>

          <div className="card relative">
            <div className="rating text-[#002F48] flex justify-center gap-1">
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
            </div>
            <p className="mt-5 text-xl font-roboto text-[#002F48] font-normal leading-8 w-[761px] mx-auto">
              "I joined SG Education in my 11th grade and stayed with them until
              I finished 12th Science. The way Latesh Sir teaches Physics and
              Maths is exceptional. He breaks down difficult concepts into
              manageable parts, which made a huge difference for me. Pooja
              Ma’am’s Chemistry classes were also very engaging, and Varsha
              Ma'am’s Biology teaching was the best! I’m now studying
              Biotechnology, and I owe a lot of my success to SG Education. It’s
              the best classes for Science students in Kalyan."
            </p>

            {/* <div className="absolute -bottom-28 right-[40%] flex flex-col justify-center items-center">
              <img src={pic1} className="" alt=" " width={117} />
              <h3 className="font-bold font-roboto text-2xl text-[#002F48]">Sharvari Gangurde</h3>
              <p className="font-normal font-roboto text-2xl text-[#002F48]">(12th Science)</p>
            </div> */}
            {/* double inverted comma */}
            <div>
              <img src={pic2} alt=""  
              className="absolute top-20  left-36" width={79} height={189}
              />
              <img src={pic3} alt="" 
              className="absolute  bottom-16 right-28" width={79} height={189}
              />
            </div>
          </div>

          <div className="card relative">
            <div className="rating text-[#002F48] flex justify-center gap-1">
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
            </div>
            <p className="mt-5 text-xl font-roboto text-[#002F48] font-normal leading-8 w-[761px] mx-auto">
              "I joined SG Education in my 11th grade and stayed with them until
              I finished 12th Science. The way Latesh Sir teaches Physics and
              Maths is exceptional. He breaks down difficult concepts into
              manageable parts, which made a huge difference for me. Pooja
              Ma’am’s Chemistry classes were also very engaging, and Varsha
              Ma'am’s Biology teaching was the best! I’m now studying
              Biotechnology, and I owe a lot of my success to SG Education. It’s
              the best classes for Science students in Kalyan."
            </p>

            {/* <div className="absolute -bottom-28 right-[40%] flex flex-col justify-center items-center">
              <img src={pic1} className="" alt=" " width={117} />
              <h3 className="font-bold font-roboto text-2xl text-[#002F48]">Sharvari Gangurde</h3>
              <p className="font-normal font-roboto text-2xl text-[#002F48]">(12th Science)</p>
            </div> */}
            {/* double inverted comma */}
            <div>
              <img src={pic2} alt=""  
              className="absolute top-20  left-36" width={79} height={189}
              />
              <img src={pic3} alt="" 
              className="absolute  bottom-16 right-28" width={79} height={189}
              />
            </div>
          </div>

          <div className="card relative">
            <div className="rating text-[#002F48] flex justify-center gap-1">
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
            </div>
            <p className="mt-5 text-xl font-roboto text-[#002F48] font-normal leading-8 w-[761px] mx-auto">
              "I joined SG Education in my 11th grade and stayed with them until
              I finished 12th Science. The way Latesh Sir teaches Physics and
              Maths is exceptional. He breaks down difficult concepts into
              manageable parts, which made a huge difference for me. Pooja
              Ma’am’s Chemistry classes were also very engaging, and Varsha
              Ma'am’s Biology teaching was the best! I’m now studying
              Biotechnology, and I owe a lot of my success to SG Education. It’s
              the best classes for Science students in Kalyan."
            </p>

            {/* <div className="absolute -bottom-28 right-[40%] flex flex-col justify-center items-center">
              <img src={pic1} className="" alt=" " width={117} />
              <h3 className="font-bold font-roboto text-2xl text-[#002F48]">Sharvari Gangurde</h3>
              <p className="font-normal font-roboto text-2xl text-[#002F48]">(12th Science)</p>
            </div> */}
            {/* double inverted comma */}
            <div>
              <img src={pic2} alt=""  
              className="absolute top-20  left-36" width={79} height={189}
              />
              <img src={pic3} alt="" 
              className="absolute  bottom-16 right-28" width={79} height={189}
              />
            </div>
          </div>

          <div className="card relative">
            <div className="rating text-[#002F48] flex justify-center gap-1">
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
            </div>
            <p className="mt-5 text-xl font-roboto text-[#002F48] font-normal leading-8 w-[761px] mx-auto">
              "I joined SG Education in my 11th grade and stayed with them until
              I finished 12th Science. The way Latesh Sir teaches Physics and
              Maths is exceptional. He breaks down difficult concepts into
              manageable parts, which made a huge difference for me. Pooja
              Ma’am’s Chemistry classes were also very engaging, and Varsha
              Ma'am’s Biology teaching was the best! I’m now studying
              Biotechnology, and I owe a lot of my success to SG Education. It’s
              the best classes for Science students in Kalyan."
            </p>

            {/* <div className="absolute -bottom-28 right-[40%] flex flex-col justify-center items-center">
              <img src={pic1} className="" alt=" " width={117} />
              <h3 className="font-bold font-roboto text-2xl text-[#002F48]">Sharvari Gangurde</h3>
              <p className="font-normal font-roboto text-2xl text-[#002F48]">(12th Science)</p>
            </div> */}
            {/* double inverted comma */}
            <div>
              <img src={pic2} alt=""  
              className="absolute top-20  left-36" width={79} height={189}
              />
              <img src={pic3} alt="" 
              className="absolute  bottom-16 right-28" width={79} height={189}
              />
            </div>
          </div>

          <div className="card relative">
            <div className="rating text-[#002F48] flex justify-center gap-1">
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
            </div>
            <p className="mt-5 text-xl font-roboto text-[#002F48] font-normal leading-8 w-[761px] mx-auto">
              "I joined SG Education in my 11th grade and stayed with them until
              I finished 12th Science. The way Latesh Sir teaches Physics and
              Maths is exceptional. He breaks down difficult concepts into
              manageable parts, which made a huge difference for me. Pooja
              Ma’am’s Chemistry classes were also very engaging, and Varsha
              Ma'am’s Biology teaching was the best! I’m now studying
              Biotechnology, and I owe a lot of my success to SG Education. It’s
              the best classes for Science students in Kalyan."
            </p>

            {/* <div className="absolute -bottom-28 right-[40%] flex flex-col justify-center items-center">
              <img src={pic1} className="" alt=" " width={117} />
              <h3 className="font-bold font-roboto text-2xl text-[#002F48]">Sharvari Gangurde</h3>
              <p className="font-normal font-roboto text-2xl text-[#002F48]">(12th Science)</p>
            </div> */}
            {/* double inverted comma */}
            <div>
              <img src={pic2} alt=""  
              className="absolute top-20  left-36" width={79} height={189}
              />
              <img src={pic3} alt="" 
              className="absolute  bottom-16 right-28" width={79} height={189}
              />
            </div>
          </div>

          <div className="card relative">
            <div className="rating text-[#002F48] flex justify-center gap-1">
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
            </div>
            <p className="mt-5 text-xl font-roboto text-[#002F48] font-normal leading-8 w-[761px] mx-auto">
              "I joined SG Education in my 11th grade and stayed with them until
              I finished 12th Science. The way Latesh Sir teaches Physics and
              Maths is exceptional. He breaks down difficult concepts into
              manageable parts, which made a huge difference for me. Pooja
              Ma’am’s Chemistry classes were also very engaging, and Varsha
              Ma'am’s Biology teaching was the best! I’m now studying
              Biotechnology, and I owe a lot of my success to SG Education. It’s
              the best classes for Science students in Kalyan."
            </p>

            {/* <div className="absolute -bottom-28 right-[40%] flex flex-col justify-center items-center">
              <img src={pic1} className="" alt=" " width={117} />
              <h3 className="font-bold font-roboto text-2xl text-[#002F48]">Sharvari Gangurde</h3>
              <p className="font-normal font-roboto text-2xl text-[#002F48]">(12th Science)</p>
            </div> */}
            {/* double inverted comma */}
            <div>
              <img src={pic2} alt=""  
              className="absolute top-20  left-36" width={79} height={189}
              />
              <img src={pic3} alt="" 
              className="absolute  bottom-16 right-28" width={79} height={189}
              />
            </div>
          </div>

          <div className="card relative">
            <div className="rating text-[#002F48] flex justify-center gap-1">
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
            </div>
            <p className="mt-5 text-xl font-roboto text-[#002F48] font-normal leading-8 w-[761px] mx-auto">
              "I joined SG Education in my 11th grade and stayed with them until
              I finished 12th Science. The way Latesh Sir teaches Physics and
              Maths is exceptional. He breaks down difficult concepts into
              manageable parts, which made a huge difference for me. Pooja
              Ma’am’s Chemistry classes were also very engaging, and Varsha
              Ma'am’s Biology teaching was the best! I’m now studying
              Biotechnology, and I owe a lot of my success to SG Education. It’s
              the best classes for Science students in Kalyan."
            </p>

            {/* <div className="absolute -bottom-28 right-[40%] flex flex-col justify-center items-center">
              <img src={pic1} className="" alt=" " width={117} />
              <h3 className="font-bold font-roboto text-2xl text-[#002F48]">Sharvari Gangurde</h3>
              <p className="font-normal font-roboto text-2xl text-[#002F48]">(12th Science)</p>
            </div> */}
            {/* double inverted comma */}
            <div>
              <img src={pic2} alt=""  
              className="absolute top-20  left-36" width={79} height={189}
              />
              <img src={pic3} alt="" 
              className="absolute  bottom-16 right-28" width={79} height={189}
              />
            </div>
          </div>

          <div className="card relative">
            <div className="rating text-[#002F48] flex justify-center gap-1">
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
              <FontAwesomeIcon icon={faStar} className="w-5 h-5" />
            </div>
            <p className="mt-5 text-xl font-roboto text-[#002F48] font-normal leading-8 w-[761px] mx-auto">
              "I joined SG Education in my 11th grade and stayed with them until
              I finished 12th Science. The way Latesh Sir teaches Physics and
              Maths is exceptional. He breaks down difficult concepts into
              manageable parts, which made a huge difference for me. Pooja
              Ma’am’s Chemistry classes were also very engaging, and Varsha
              Ma'am’s Biology teaching was the best! I’m now studying
              Biotechnology, and I owe a lot of my success to SG Education. It’s
              the best classes for Science students in Kalyan."
            </p>

            {/* <div className="absolute -bottom-28 right-[40%] flex flex-col justify-center items-center">
              <img src={pic1} className="" alt=" " width={117} />
              <h3 className="font-bold font-roboto text-2xl text-[#002F48]">Sharvari Gangurde</h3>
              <p className="font-normal font-roboto text-2xl text-[#002F48]">(12th Science)</p>
            </div> */}
            {/* double inverted comma */}
            <div>
              <img src={pic2} alt=""  
              className="absolute top-20  left-36" width={79} height={189}
              />
              <img src={pic3} alt="" 
              className="absolute  bottom-16 right-28" width={79} height={189}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TestimonialCard;